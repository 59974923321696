import Button from 'components/Forms/Button/Button';
import { ButtonDefaultPropType } from 'components/Forms/Button/Button';
import { mobileFirstSizes } from 'components/Theme/mediaQueries';
import { useComponentUpdate } from 'hooks/helpers/UseComponentUpdate';
import { useLatest } from 'hooks/ui/useLatest';
import { useMediaMin } from 'hooks/ui/useMediaMin';
import { ArrowIcon, ArrowUpIcon } from 'public/svg/IconsSvg';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import twMerge from 'utils/twMerge';

export type ButtonWrapperType = {
    buttonAlign?: 'left' | 'center';
};

export type LimitHeightType = {
    mobileHeight: number;
    desktopHeight?: number;
};

export type LimitHeightWrapType = LimitHeightType &
    ButtonWrapperType & {
        isWithButtonIcons?: boolean;
        buttonLabelLess?: string;
        buttonLabelMore: string;
        buttonVariant?: ButtonDefaultPropType['variant'];
        desktopSizeDelimiter?: number;
        increaseParentCounter?: () => void;
        updateDependency?: string | number;
        buttonClassName?: string;
        isDefaultCollapsed?: boolean;
        isDefaultEnabled?: boolean;
    };

const LimitHeightWrap: FC<LimitHeightWrapType> = ({
    increaseParentCounter,
    buttonAlign,
    isWithButtonIcons,
    buttonLabelLess,
    buttonLabelMore,
    buttonVariant,
    children,
    desktopHeight,
    desktopSizeDelimiter,
    mobileHeight,
    updateDependency,
    buttonClassName,
    isDefaultCollapsed,
    isDefaultEnabled = true,
}) => {
    const [isCollapsed, setIsCollapsed] = useState(isDefaultCollapsed);
    const [isEnabled, setIsEnabled] = useState(isDefaultEnabled);
    const wrapperRef = useRef<HTMLDivElement>(null);

    const onClickHandler = useCallback(() => {
        setIsCollapsed((prev) => {
            if (increaseParentCounter) {
                increaseParentCounter();
            }

            return !prev;
        });
    }, [increaseParentCounter]);

    const handleEnabled = useCallback(
        (windowWidth: number) => {
            setIsEnabled(() => {
                const node = wrapperRef.current;
                if (!node) {
                    return false;
                }
                const defaultSizeDelimiter = desktopSizeDelimiter ?? mobileFirstSizes.vl;
                if (windowWidth <= defaultSizeDelimiter) {
                    return node.scrollHeight > mobileHeight;
                } else if (desktopHeight === undefined) {
                    return false;
                }

                return node.scrollHeight > desktopHeight;
            });
        },
        [desktopSizeDelimiter, mobileHeight, desktopHeight],
    );

    const latestHandleEnabled = useLatest(handleEnabled);

    useEffect(() => {
        const handler = () => latestHandleEnabled.current(window.innerWidth);
        handler();
        window.addEventListener('resize', handler, { passive: true });

        return () => {
            window.removeEventListener('resize', handler);
        };
    }, [latestHandleEnabled]);

    useComponentUpdate(() => {
        latestHandleEnabled.current(window.innerWidth);
        setIsCollapsed(true);
    }, [latestHandleEnabled, updateDependency]);

    const isDesktop = useMediaMin('vl');
    const collapsedHeight = isDesktop ? (desktopHeight !== undefined ? `${desktopHeight}px` : 'auto') : mobileHeight;

    return (
        <>
            {isEnabled && isCollapsed ? (
                <div className="limit-height overflow-hidden" style={{ height: collapsedHeight }} ref={wrapperRef}>
                    {children}
                </div>
            ) : (
                <div className="limit-height h-auto overflow-visible after:hidden" ref={wrapperRef}>
                    {children}
                </div>
            )}
            {isEnabled && (isCollapsed || buttonLabelLess) && (
                <div
                    className={
                        buttonAlign !== 'left' ? 'relative bottom-0 z-two -translate-y-9 text-center' : undefined
                    }
                >
                    <Button
                        className={twMerge('whitespace-nowrap', buttonClassName)}
                        type="button"
                        size="smallNarrow"
                        variant={buttonVariant}
                        onClick={onClickHandler}
                    >
                        {isCollapsed ? (
                            <>
                                {isWithButtonIcons && <ArrowIcon className="h-3 w-3" />}
                                <span>{buttonLabelMore}</span>
                            </>
                        ) : (
                            <>
                                {isWithButtonIcons && <ArrowUpIcon className="h-3 w-3" />}
                                <span>{buttonLabelLess}</span>
                            </>
                        )}
                    </Button>
                </div>
            )}
        </>
    );
};

export default LimitHeightWrap;
