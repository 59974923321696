import Heading from 'components/Basic/Heading/Heading';
import ProductsSlider from 'components/Blocks/Product/ProductsSlider';
import Webline from 'components/Layout/Webline/Webline';
import { usePromotedProducts } from 'connectors/products/Products';
import useTranslation from 'next-translate/useTranslation';

const GTM_LIST_NAME = 'promo products' as const;

const PromotedProducts: FC = () => {
    const promotedProducts = usePromotedProducts();
    const { t } = useTranslation();

    if (promotedProducts !== undefined && promotedProducts.length > 0) {
        return (
            <Webline>
                <Heading type="h2">{t('Most popular products')}</Heading>
                <ProductsSlider products={promotedProducts} gtmListName={GTM_LIST_NAME} />
            </Webline>
        );
    }

    return null;
};

export default PromotedProducts;
