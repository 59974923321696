import AlternativeUrls from 'components/Basic/Head/AlternativeUrls/AlternativeUrls';
import { SearchMetadata } from 'components/Basic/Head/SearchMetadata/SearchMetadata';
import Heading from 'components/Basic/Heading/Heading';
import HomepageArticle from 'components/Blocks/ArticlePreview/ArticlePreview';
import BelowNoveltiesBannerCollection from 'components/Blocks/Banners/Collection/BelowNoveltiesBannerCollection';
import TescomaWorldBannerCollection from 'components/Blocks/Banners/Collection/TescomaWorldBannerCollection';
import BannerBelowFavoriteCategories from 'components/Blocks/Banners/Position/BannerBelowFavoriteCategories';
import BannerBenefits from 'components/Blocks/Banners/Position/BannerBenefits';
import BannerOverFavoriteProducts from 'components/Blocks/Banners/Position/BannerOverFavoriteProducts';
import BlogPreview from 'components/Blocks/BlogPreview/BlogPreview';
import Carousel from 'components/Blocks/Carousel/Carousel';
import PromotedCategories from 'components/Blocks/Categories/PromotedCategories/PromotedCategories';
import CategoriesByFlag from 'components/Blocks/Product/CategoriesByFlag/CategoriesByFlag';
import PromotedProducts from 'components/Blocks/Product/PromotedProducts/PromotedProducts';
import { showSuccessMessage } from 'components/Helpers/Toasts';
import Webline from 'components/Layout/Webline/Webline';
import { blogPreviewVariables } from 'connectors/articleInterface/blogArticle/BlogArticle';
import { useSettings } from 'connectors/Settings/Settings';
import {
    BannerSuiteQueryDocumentApi,
    BlogArticlesQueryDocumentApi,
    BlogArticlesQueryVariablesApi,
    CategoriesTypeFlagQueryDocumentApi,
    PromotedCategoriesQueryDocumentApi,
    PromotedProductsQueryDocumentApi,
    SliderItemsQueryDocumentApi,
    useWatchDogRemoveApi,
} from 'graphql/generated';
import { initServerSideProps, ServerSidePropsType } from 'helpers/InitServerSideProps';
import { getServerSidePropsWrapper } from 'helpers/serverSide/getServerSidePropsWrapper';
import { useGtmStaticPageView } from 'hooks/gtm/useGtmStaticPageView';
import { usePersoo } from 'hooks/persoo/usePersoo';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import { useEffectOnce } from 'hooks/ui/useEffectOnce';
import { PERSOO_IDS } from 'modules/persoo/persooTypes';
import { useRouter } from 'next/router';
import { NextPageExtended } from 'types/global';
import { useGtmStaticPageViewEvent } from 'utils/Gtm/EventFactories';

const Homepage: NextPageExtended<ServerSidePropsType> = () => {
    const router = useRouter();
    const t = useTypedTranslationFunction();
    const { isPersooAllowed } = usePersoo();
    const [, watchDogRemove] = useWatchDogRemoveApi();
    const settings = useSettings();

    useEffectOnce(() => {
        if (router.query.removeWatchDog !== undefined) {
            (async () => {
                const { data: { WatchDogRemove: productName } = { WatchDogRemove: null } } = await watchDogRemove({
                    uuid: router.query.removeWatchDog as string,
                });

                if (productName !== null) {
                    showSuccessMessage(t('Watch dog on product {{ productName }} has been removed.', { productName }));
                }

                await router.push('/');
            })();
        }
    });

    const gtmStaticPageViewEvent = useGtmStaticPageViewEvent('home');
    useGtmStaticPageView(gtmStaticPageViewEvent);

    return (
        <>
            <SearchMetadata />
            <AlternativeUrls alternativeUrls={settings?.HomePageAlternateUrls} />
            <Webline removePaddingOnMobile>
                <Carousel />
            </Webline>
            <Webline>
                <Heading type="h2">{t('Favorite categories')}</Heading>
                <PromotedCategories />
            </Webline>
            <CategoriesByFlag />
            <Webline>
                <BannerBelowFavoriteCategories />
            </Webline>
            <BelowNoveltiesBannerCollection />
            <BannerOverFavoriteProducts />
            {isPersooAllowed && <div id={PERSOO_IDS.homepageCarousel} />}
            <PromotedProducts />
            <Webline type="blog">
                <BlogPreview />
            </Webline>
            <TescomaWorldBannerCollection />
            <BannerBenefits />
            <HomepageArticle />
        </>
    );
};

Homepage.layout = 'withLastVisitedProducts';

export const getServerSideProps = getServerSidePropsWrapper(({ redisClient, domainConfig, t }) => async (context) => {
    return await initServerSideProps<BlogArticlesQueryVariablesApi>({
        context,
        redisClient,
        domainConfig,
        t,
        prefetchedQueries: [
            { query: PromotedCategoriesQueryDocumentApi },
            { query: SliderItemsQueryDocumentApi },
            { query: BannerSuiteQueryDocumentApi },
            { query: PromotedProductsQueryDocumentApi },
            { query: CategoriesTypeFlagQueryDocumentApi },
            { query: BlogArticlesQueryDocumentApi, variables: blogPreviewVariables },
        ],
    });
});
export default Homepage;
