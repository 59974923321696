import { ExtendedNextLink } from 'components/Basic/ExtendedNextLink/ExtendedNextLink';
import ProductsSlider from 'components/Blocks/Product/ProductsSlider';
import Webline from 'components/Layout/Webline/Webline';
import { useCategoriesTypeFlag } from 'connectors/categories/CategoriesTypeFlag';
import useTranslation from 'next-translate/useTranslation';
import { ChevronRightIcon } from 'public/svg/IconsSvg';

const CategoriesByFlag: FC = () => {
    const categoriesTypeFlag = useCategoriesTypeFlag();
    const { t } = useTranslation();

    if (categoriesTypeFlag !== undefined && categoriesTypeFlag.length !== 0) {
        return (
            <>
                {categoriesTypeFlag.map((categoryTypeFlag) => (
                    <Webline key={categoryTypeFlag.category.slug}>
                        <header className="flex items-center">
                            <h2 className="mr-5 text-h5 font-bold normal-case md:text-h2">
                                {categoryTypeFlag.category.name}
                            </h2>
                            <ExtendedNextLink
                                type="category"
                                href={categoryTypeFlag.category.slug}
                                className="ml-auto flex items-center whitespace-nowrap text-h6 font-medium text-primary underline decoration-grayDarker underline-offset-4 hover:text-primary hover:decoration-primary md:ml-14"
                            >
                                <>
                                    <span>{t('All most recent products')}</span>
                                    <ChevronRightIcon className="ml-2 h-2.5 w-1.5 -translate-y-[2px] text-inherit" />
                                </>
                            </ExtendedNextLink>
                        </header>
                        <ProductsSlider products={categoryTypeFlag.products} gtmListName="promo products" />
                    </Webline>
                ))}
            </>
        );
    }

    return null;
};

export default CategoriesByFlag;
